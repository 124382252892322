.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Container iframe {
  flex-grow: 1;
}
